<template>
  <div id="home">
    <div class="standingPainting1">
        
      <transition
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInBottomRight"
        leave-active-class="animate__fadeOutBottomRight"
      >
        <img class="imgBOW" src="http://img.saohimeyue.space/img?name=Bow.png" alt="" key="1" v-if="stFlag">
      </transition>
        
      <transition-group
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInRight"
        leave-active-class="animate__fadeOutRight"
      >
      <img class="imgSP" src="http://img.saohimeyue.space/img?name=home1.png" alt="" v-show="hFlag[0]" v-if="stFlag" key="S1">
      <img class="imgSP" src="http://img.saohimeyue.space/img?name=home2.png" alt="" v-show="hFlag[1]" v-if="stFlag" key="S2">
      <img class="imgSP" src="http://img.saohimeyue.space/img?name=home3.png" alt="" v-show="hFlag[2]" v-if="stFlag" key="S3">
      <img class="imgSP" src="http://img.saohimeyue.space/img?name=home4.png" alt="" v-show="hFlag[3]" v-if="stFlag" key="S4">
      </transition-group>
  

      <transition
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInBottomLeft"
        leave-active-class="animate__fadeOutBottomLeft"
      >
        <img class="imgTEXT" src="http://img.saohimeyue.space/img?name=hometext.png" alt="" v-if="stFlag">
      </transition>
      <transition
        appear
        name="animate__animated animate__bounce"
        enter-active-class="animate__fadeInRight"
        leave-active-class="animate__fadeOutRight"
      >
      <el-button type="success" icon="el-icon-refresh" circle key="B2" class="homeBut" v-if="stFlag" @click="refresh"></el-button>
      </transition>
      <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" id="ICP">备案号:苏ICP备2022018898号-1</el-link>
    </div>
  </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'vueHome',
  props: {
  },
  data() {
    return {
      stFlag:true,
      hFlag:[true,false,false,false]
    };
  },
  methods: {
    refresh(){
      let zero=this.hFlag[3];
      for (let index = this.hFlag.length-1; index >=0; index--) {
        if(index == 0){
          this.$set(this.hFlag,index,zero);
          // this.hFlag[index]=zero;
        }
        else{
          this.$set(this.hFlag,index,this.hFlag[index-1]);
          // this.hFlag[index]=this.hFlag[index-1];
        }
      }
    }
  },
  mounted(){
    this.$bus.$on('divFlag1',(f)=>{
      this.stFlag=f;
    })

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* min-width: 1520px;
  min-height: 960px; */
}
.standingPainting1{
  width: 100%;
  height: 100%;
}
.imgSP{
  position: absolute;
  bottom: -8.5%;
  left: -23%;
  height: 152%;
  object-fit:contain;
  z-index: 12;
}
.imgTEXT{
  object-fit:contain;
  position: absolute;
  height: 100%;
  left: 2%;
  bottom: -7%;
  z-index: 20;
}


.imgBOW{
  object-fit:contain;
  position: absolute;
  width: 100%;
  bottom: -8%;
  z-index: 0;
}

#ICP{
  position: absolute;
  bottom: 3px;
  right: 4px;
}
.homeBut{
  position: absolute;
  /* left: 38.5%; */
  /* bottom: 29%; */
  top: 7%;
  right: 4.5%;
  background-color: #11d3bc;
  border: 3px solid white;
  z-index: 30;
}
.homeBut:focus{
  background-color: #11c3ae;
  border: 3px solid white;
}
.homeBut:hover{
  background-color: #11b9a6;
  border: 3px solid white;
}

@media only screen and (max-width: 480px){
  #home{
    min-width: 200px;
    min-height: 200px;
  }
  .imgBOW{
    width: auto;
    height: 90%;
    bottom: -8%;
    z-index: 15;
  }
  .imgTEXT{
    bottom: 0.5%;
    width: 155%;
    height: auto;
    z-index: 20;

  }
  .imgSP{
    height: 110%;
    bottom: 0%;
    left: -180%;
    /* z-index: 12; */

  }
}
</style>
