<template>
  <div class="setd">
      
      <div class="ctrl_but">
        <el-button type="warning" icon="el-icon-headset" circle @click="musicIn"></el-button>
        <el-button type="danger" icon="el-icon-headset" circle @click="musicCat"></el-button>
        <el-button type="success" icon="el-icon-link" circle @click="goMoegirl"></el-button>
        <el-button type="primary" icon="el-icon-user" circle @click="goUser"></el-button>
        <el-button class="bu1" type="success" icon="el-icon-caret-left" circle @click="rotateChL"></el-button>
        <el-button class="bu2" type="success" icon="el-icon-caret-right" circle @click="rotateChR"></el-button>
        <div v-show="musicFlag">
            <audio :src="require(`./music/${obj}.mp3`)" ref="audio" v-for="(obj,index) in musicList" :key='index'></audio>
            <audio src="./music/jijian.mp3" ref="In"></audio>
        </div>

      </div>
      
      
      <div class="setdz" :style="{transform:` rotateY(${roy}deg)`}">
        <transition>
            <div v-show="showList[0]" :style="{transform: `rotateY(0deg) translateZ(${tzw}px)`}" ref="d1"><img src="http://img.saohimeyue.space/img?name=yue.png" alt=""></div>
        </transition>
        <transition>
            <div v-show="showList[1]" :style="{transform: `rotateY(90deg) translateZ(${tzw}px)`}" ><img src="http://img.saohimeyue.space/img?name=yue2.png" alt=""></div>
        </transition>
        <transition>
            <div v-show="showList[2]" :style="{transform: `rotateY(180deg) translateZ(${tzw}px)`}" ><img src="http://img.saohimeyue.space/img?name=yue3.png" alt=""></div>
        </transition>
        <transition>
            <div v-show="showList[3]" :style="{transform: `rotateY(270deg) translateZ(${tzw}px)`}" ><img src="http://img.saohimeyue.space/img?name=yue4.png" alt=""></div>
        </transition>
      </div>
  </div>
</template>

<script>
export default {
  name: 'vueSet',
  data() {
    return {
      roy:0,
      tzw:230,
      tzwti:'',
      showList:[true,true,false,true],
      musicFlag:false,
      musicList:[100,200,300,400,500,600,700,800,600,300]
    };
  },
  methods: {
    musicIn(){
        this.$refs.In.play();
    },
    musicCat(){
        let num =Math.ceil(Math.random()*10);
        this.$refs.audio[num].play();
    },
    goUser(){
        window.open('https://space.bilibili.com/1923344666/');
    },
    goMoegirl(){
        window.open('https://zh.moegirl.org.cn/%E4%BD%90%E4%BF%9D%E5%A7%AC%E5%A4%95%E6%98%A0');
    },
    rotateChR(){
        let royz =Math.abs((this.roy+90)/90%4);
        switch (royz) {
            case 0:{
                for (const key in this.showList) {
                    if(key==2){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;
            case 1:{
                let ks;
                if(this.roy>=0){
                    ks=1;
                }
                else{
                    ks=3;
                }
                for (const key in this.showList) {
                    if(key==ks){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;
            case 2:{
                for (const key in this.showList) {
                    if(key==0){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;  
            case 3:{
                let ks;
                if(this.roy>=0){
                    ks=3;
                }
                else{
                    ks=1;
                }
                for (const key in this.showList) {
                    if(key==ks){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;                     
        }
        this.roy+=90;
    },
    rotateChL(){
        let royz =Math.abs((this.roy-90)/90%4);
        switch (royz) {
            case 0:{
                for (const key in this.showList) {
                    if(key==2){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;
            case 1:{
                let ks;
                if(this.roy>0){
                    ks=1;
                }
                else{
                    ks=3;
                }
                for (const key in this.showList) {
                    if(key==ks){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;
            case 2:{
                for (const key in this.showList) {
                    if(key==0){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;  
            case 3:{
                let ks;
                if(this.roy>0){
                    ks=3;
                }
                else{
                    ks=1;
                }
                for (const key in this.showList) {
                    if(key==ks){
                        this.showList[key]=false;
                    }
                    else{
                        this.showList[key]=true;
                    }
                }  
            }break;                     
        }
        this.roy-=90;
    },
    winCh(){
        this.tzwti=setTimeout(()=>{
            if(this.tzw<this.$refs.d1.offsetWidth/2){
                this.tzw=this.$refs.d1.offsetWidth/2;
                clearTimeout(this.tzwti);
            }
        },600)
    }
        
    
  },
  mounted () {
    this.winCh();
    window.addEventListener('resize',this.winCh);
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.winCh);
  }
}
</script>


<style scoped>
    .setd{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */

    }
    img{
        width: 100%;
        /* border: 1px solid red; */

    }
    .setdz{
        width:100%;
        transform-style: preserve-3d;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 1s;
    }
    .setdz div{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100px; */
        height: 100%;
        /* border: 1px solid red; */

    }
    /* .bu1{
        position: absolute;
        z-index: 21;
        left: 10%;
        bottom: 0%;
    }
    .bu2{
        position: absolute;
        z-index: 21;
        right: 10%;
        bottom: 0%;

    } */
    .ctrl_but{
        position: absolute;
        z-index: 21;
        right: 5%;
        top: 5%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(90deg) translateX(50%) translateY(-280%);
    }
    .ctrl_but button{
        transform: rotate(-90deg);
    }
    .v-enter-active{
        animation: c 0.8s;
    }

    .v-leave-active{
        animation: c 0.8s reverse;
    }

    @keyframes c{
        0%{
             opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
</style>