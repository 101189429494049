import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
// import axios from 'axios'
import { Timeline,TimelineItem,Card,Button,Tag,Link,Tabs,TabPane,Carousel,CarouselItem,Loading,Slider,Menu,MenuItem,RadioGroup,Radio} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


//保持旧路由实例原型上的push方法
let originPush = VueRouter.prototype.push;

//对路由原型上的push方法二次封装，location：原push方法需要的路由路径，resolve,reject：成功与失败的回调
VueRouter.prototype.push = function(location,resolve,reject){
  //如果有传入成功与失败的回调，则调用原push方法，传入参数，
  //使用call改变originPush的this指向（引用let originPush其this已经变成windows）
  //否则传入路由路径以及空回调
  if(resolve&&reject){
    originPush.call(this,location,resolve,reject);
  }
  else{
    originPush.call(this,location,()=>{},()=>{});
  }

}
 

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Link);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Slider);
Vue.use(Loading.directive);
Vue.use(MenuItem);
Vue.use(Menu);
Vue.use(Radio);
Vue.use(RadioGroup);
// Vue.use(Select);

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store:store,
  beforeCreate(){
    Vue.prototype.$bus =this;
    Vue.prototype.$loading = Loading.service;
  }
}).$mount('#app')
