import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/Home'  // 引入组件
import content from '../components/Content'  
import introduce from '../components/introduce'  

Vue.use(VueRouter)

const routes = [
   {
      name:'home',
      path: '/home',
      component: home
   },
   {
      name:'content',
      path: '/content',
      component: content
   },
   {
      name:'introduce',
      path: '/introduce',
      component: introduce
   },
        //重定向
   {
      path:'*',
      redirect: '/home'
   }

]

const router = new VueRouter({
   // 把常量routes放进来
   routes
})

// 导出router
export default router





