<template>
    <div id="content">
        <div class="containerz">
            <transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__backInDown"
            leave-active-class="animate__fadeOutUp"
            >
            <div class="royImg" v-if="conFlag">
                <el-carousel :height="pcH"  :autoplay="false">
                    <el-carousel-item v-for="item in Limg" :key="item.src">
                        <img :src="`http://img.saohimeyue.space/img?name=${item.src}.jpg`" alt="">
                        <el-button icon="el-icon-link" type="success" circle @click="goLink(item.a)"></el-button>
                    </el-carousel-item>
                </el-carousel>
            </div>
            </transition>





            <transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__slideInRight"
            leave-active-class="animate__slideOutRight"
            >
            <div class="HorS" v-if="conFlag">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="HISTORY">HISTORY</el-menu-item>
                    <el-menu-item index="SAYING">SAYING</el-menu-item>
                    <el-menu-item index="PHOTO" v-if="photoF">PHOTO</el-menu-item>
                </el-menu>

                <div class="HDiv" v-show="this.activeIndex=='HISTORY'">
                    <div class="radio">
                        <el-radio-group v-model="reverse">
                        <el-radio :label="true">倒序</el-radio>
                        <el-radio :label="false">正序</el-radio>
                        </el-radio-group>
                    </div>
                    <el-timeline :reverse="reverse">
                        <el-timeline-item v-for="activity in activitiesHistory" :key="activity.ids" :timestamp="activity.timestamp" placement="top" >
                            <el-card>
                                <h3>{{activity.text}}</h3>
                                <div v-if="activity.vided">
                                    <el-link  type="info"   v-for="(i,index) in videdJson(activity.vided)" :key="i" :href="i" :underline="false" target="_Blank">{{index}}</el-link>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                
                <div class="SDiv" v-show="this.activeIndex=='SAYING'">
                    <div class="radio">
                        <el-radio-group v-model="reverse">
                        <el-radio :label="true">倒序</el-radio>
                        <el-radio :label="false">正序</el-radio>
                        </el-radio-group>
                    </div>
                    <el-timeline :reverse="reverse">
                        <el-timeline-item v-for="activity in activitiesSaying" :key="activity.ids" :timestamp="activity.timestamp" placement="top" id="g-t">
                            <el-card class="g-card">
                                <h3>{{activity.text}}</h3>
                                <div  class="cardMu" v-if="(activity.mvData!=null)">
                                    <el-button type="success" icon="el-icon-headset" circle @click="palySaying(JSON.parse(activity.mvData).id)"></el-button>
                                    <audio :ref="`mu${JSON.parse(activity.mvData).id}`" :src="require(`../components/music/${JSON.parse(activity.mvData).mp3Src}.mp3`)"></audio>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>

                <el-tab-pane label="Photo" name="Photo" v-if="photoF" v-show="this.activeIndex=='PHOTO'">
                    <div class="royimg2">
                    <el-carousel :height="modeH" :autoplay="false">
                        <el-carousel-item v-for="item in Limg" :key="item.src">
                            <div class="imgDiv2">
                            <img :src="`http://img.saohimeyue.space/img?name=${item.src}.jpg`" alt="">
                            <el-button icon="el-icon-link" type="success" circle @click="goLink(item.a)"></el-button>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    </div>
                </el-tab-pane>

            </div>
            </transition>

            
        </div>   
    </div>
  </template>
  
  <script>
  export default {
    name: 'vueContent',
    props: {
      
    },
    data() {
      return {
        activeName: 'History',
        roy:'50',
        Limg:[{src:'l2',a:'https://t.bilibili.com/684942521123471415'},{src:'l1',a:null},{src:'l3',a:'https://t.bilibili.com/644075430375063571'},{src:'l4',a:null}],
        conFlag:true,
        activitiesHistory:[],
        activitiesSaying:[],
        modeH:'0px',
        pcH:'0px',
        photoF:true,
        activeIndex:'HISTORY',
        reverse: true,
      };
    },
    methods: {
        handleSelect(index){
            this.activeIndex=index;
        },
        goLink(t){
            if(t!=null){
                window.open(t);
            }
        },
        palySaying(name){ 
            this.$refs[`mu${name}`][0].play();
        },
        // 传送门模块的数据解析，数据来源于activity.vided，如果存在则解析，如果不存在则返回准备好的空数据
        videdJson(k){
        if(k!=null){
            return JSON.parse(k);
        }
        else{
            return this.nullvideData;
        }
        },
        getAxiosHistory(){
            this.$store.dispatch('activitiesHistoryChange')
            .then(()=>{
                this.activitiesHistory=this.$store.state.activitiesHistory;
                
            })
        },
        getAxiosSaying(){
            this.$store.dispatch('activitiesSayingChange')
            .then(()=>{
                this.activitiesSaying=this.$store.state.activitiesSaying;
            })
        },
        
    },
    mounted(){
        this.$bus.$on('divFlag3',(f)=>{
            this.conFlag=f;
        })
        if(window.innerWidth<480){
            this.photoF=true;
        }
        else{
            this.photoF=false;
        }
        this.getAxiosHistory();
        this.getAxiosSaying();
        this.modeH=`${window.innerHeight*0.86-75}px`;
        this.pcH=`${window.innerHeight*0.87-10}px`;
        window.addEventListener('resize',()=>{
            this.modeH=`${window.innerHeight*0.86-75}px`;
            
            if(window.innerHeight<695){
                this.pcH=`${695*0.87-10}px`;
            }else{
                this.pcH=`${window.innerHeight*0.87-10}px`;
            }
            if(window.innerWidth<480){
                this.photoF=true;
            }
            else{
                this.photoF=false;
                this.activeIndex='HISTORY';
            }
        })
        
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    #content{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        
        /* min-width: 1520px;
        min-height: 960px; */
    }
    .containerz{
        /* border: 1px solid red; */
        position: absolute;
        top: 3.5%;
        left: 6%;
        width: 89%;
        padding-left:1.5% ;
        padding-right:1.5% ;
        display: flex;
        flex-direction: row;
        height: 87%;
    }

    .el-card{
        border: none;
        border-radius:45px;
        /* pointer-events: none; */
        width: 100%;
        height: 100%;
        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
        background: transparent;
        backdrop-filter: blur(15px);
        transition: 0.2s;
        border-left: 5px solid #67C23A;
        border-right: 5px solid #67C23A;
    }
    .g-card{
        border-left: 5px solid palevioletred;
        border-right: 5px solid palevioletred;
    }

    .el-card h3{
        margin-bottom: 5px;
    }
    .g-card h3{
        margin-bottom: 5px;
    }
    .cardMu{
        position: absolute;
        top: 0%;
        right: 0%;
        padding-left: 5px;
        /* border: 1px solid red; */
        width: 60px;
        height: 100%;
        display: flex;
        align-items:center;
    }

    .cardMu button{
        widows: 40px;
        height: 40px;
        border: 0px;
        background-color: rgb(239, 137, 171);
    }

    .cardMu button:hover{
        background-color: rgba(239, 137, 171, 0.511);
    }
    .cardMu button:focus{
        background-color: rgb(228, 116, 154);
    }

    .HorS{
        /* position: relative; */
        /* border: 1px solid red; */
        padding-left: 48px;
        padding-top: 5px;
        padding-right: 30px;
        width: 60%;
        height: 100%;
        overflow: hidden;
    }
    .HDiv{
        margin-top: 15px;
        /* border: 1px solid red; */
        width: 100%;
        height: calc(100% - 68px);
        overflow: scroll;
        padding-right: 30px;
        padding-bottom: 10px;
    }
    .HDiv::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    
    }
    /* 两个滚动条交接处 -- x轴和y轴 */
    .HDiv::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* 滚动条滑块 */
    .HDiv::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #52ac25;
    }

    /* 滚动条轨道 */
    .HDiv::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ededed;
    }

    .SDiv{
        /* border: 1px solid red; */
        width: 100%;
        margin-top: 15px;
        height: calc(100% - 68px);
        overflow: scroll;
        padding-right: 30px;
        padding-bottom: 10px;
    }
    .SDiv::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    
    }
    /* 两个滚动条交接处 -- x轴和y轴 */
    .SDiv::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* 滚动条滑块 */
    .SDiv::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(216, 112, 147, 0.776);
    }

    /* 滚动条轨道 */
    .SDiv::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ededed;
    }

    
    .saying{
        /* border: 1px solid red; */
        width: 75%;
    }

    .royImg{
        width: 40%;
        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
        background: transparent;
        backdrop-filter: blur(15px);
        padding: 5px;
    }

    .royImg img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit:cover;
    }
    .royImg button{
        position: absolute;
        bottom: 20px;
        right: 20px;
        border: 3px solid rgb(255, 255, 255);
        background-color: rgb(229, 228, 228);
    }
    .royImg button:hover{
        background-color: #eaeaea6c;
    }
    .royImg button:focus{
        background-color: #eaeaeae7;
    }
    .royimg2{
        position: relative;
        width: 100%;
        padding: 6px;
        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
        background: transparent;
        backdrop-filter: blur(15px);
    }
    .imgDiv2{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content:center;
    }
    .radio{
        width: 100%;
        border-top: 3px solid #E4E7ED;
        background: transparent;
        backdrop-filter: blur(15px);
        /* border: 1px solid red; */
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        padding-left: 25px;
    }
    .royimg2 img{
        height: 100%;
    }
    .royimg2 button{
        position: absolute;
        bottom: 20px;
        right: 20px;
        border: 3px solid rgb(255, 255, 255);
        background-color: rgb(229, 228, 228);
    }
    .royimg2 button:hover{
        background-color: #eaeaea6c;
    }
    .royimg2 button:focus{
        background-color: #eaeaeae7;
    }
    @media only screen and (max-width: 480px){
        #content{
            min-width: 200px;
            min-height: 200px;  
        }
        .royImg{
            display: none;
        }
        .containerz{
            width: 95%;
            left: 2.5%;
            padding: 0%;
        }
        .HorS{
            /* border: 1px solid red; */
            padding: 0px;
            width: 100%;
        }
        .SDiv{
            padding: 13px;
        }
        .HDiv{
            padding: 13px;
        }
    }
  </style>

  <style>
    #content .el-tabs__active-bar{
        background-color:#67C23A;
        height: 4px;
    }
    #content .el-tabs__item.is-active{
        color: #67C23A;
    }

    #content .el-menu{
        border-top: 3px solid #E4E7ED;
        background: transparent;
        backdrop-filter: blur(15px);
        padding-top: 6px;
        padding-bottom: 2px;
        padding-left: 18px;
        /* border-radius:25px 25px 0% 0%; */
    }
    #content .el-menu-item{
        background: transparent;
        border-bottom: 2px solid #67C23A;
    }
    
    #content .el-menu-item:hover{
        background: transparent;
    }
    #content .el-menu-item:focus{
        background: transparent;
        border-bottom: 2px solid #67C23A;
    }
    #content .el-timeline-item__node{
        background-color:#67C23A;
    }
    #g-t .el-timeline-item__node{
        background-color:palevioletred;
    }
    #content .el-timeline-item__timestamp{
        font-size: 15px;
        color: black;
    }
</style>
  