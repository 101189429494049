import Vue from 'vue';
import Vuex from 'vuex';
import history from '../json/history.json'
import saying from '../json/saying.json'
Vue.use(Vuex)

const actions ={
    //获取精灵历史数据
    activitiesHistoryChange:function(context){
        // 后端请求链接从../../public/aixosUrlList.js文件中读取
        // return axios.get(require('../../public/aixosUrlList.js').axiosUrlList[0])
        //     .then(res => {
        //         // 调用commit，将响应体中的data部分传给函数ActivitiesHistoryChange进行后续解析
        //         context.commit('ActivitiesHistoryChange',res.data);
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         context.commit('ActivitiesHistoryChange',[{id:1,timestamp:'警告',text:'服务器宕机'}]); 
        //     })
        return new Promise((resolve) => {
            context.commit('ActivitiesHistoryChange',history[2].data)
            resolve();

        })
    },

    //获取精灵语录数据
    activitiesSayingChange:function(context){
        // console.log(history[2].data);
        // return axios.get(require('../../public/aixosUrlList.js').axiosUrlList[1])
        //     .then(res => {
        //         context.commit('ActivitiesSayingChange',res.data);
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         context.commit('ActivitiesSayingChange',[{id:'1s',timestamp:'警告',text:'服务器宕机'}]);
        //     });
        return new Promise((resolve) => {
            context.commit('ActivitiesSayingChange',saying[2].data);
            resolve();
        })
        
    },
}

const mutations={
    ActivitiesHistoryChange:function(state,value){
        state.activitiesHistory=value;   
    },
    ActivitiesSayingChange:function(state,value){
        state.activitiesSaying=value;   
    },
}

const state={
    activitiesHistory:null,
    activitiesSaying:null,
}

export default new Vuex.Store({
    actions,
    mutations,
    state
})