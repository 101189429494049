<template>
  <div id="app">
    <router-view></router-view>
    <div class="operate" >
      <button class="butz" style="  position: absolute; left:18%;" ref="but1"><el-button type="primary" icon="el-icon-house" circle @click="goHome"></el-button></button>
      <button class="butz" style="  position: absolute; right:50%; transform: translateX(50%);" ref="but2"><el-button type="primary" icon="el-icon-postcard" circle @click="goIntroduce"></el-button></button>
      <button class="butz" style="  position: absolute; right:18%;" ref="but3"><el-button type="primary" icon="el-icon-reading" circle @click="goContent"></el-button></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      loadingF:true,
    }
  },
  methods:{
    goHome(){
      if(this.$route.name=="introduce"){
        this.$bus.$emit('divFlag2',false);
      }
      if(this.$route.name=="content"){
        this.$bus.$emit('divFlag3',false);
      }
      setTimeout(()=>{
        this.$router.push({ name:'home'});
      },500);
    },
    goContent(){
      if(this.$route.name=="home"){
        this.$bus.$emit('divFlag1',false);
      }
      if(this.$route.name=="introduce"){
        this.$bus.$emit('divFlag2',false);
      }
      setTimeout(()=>{
        this.$router.push({ name:'content'});
      },500);
    },
    goIntroduce(){
      if(this.$route.name=="home"){
        this.$bus.$emit('divFlag1',false);
      }
      if(this.$route.name=="content"){
        this.$bus.$emit('divFlag3',false);
      }
      setTimeout(()=>{
        this.$router.push({ name:'introduce'})
      },500);
    },
  },
  watch:{
    '$route'(to){
      if(to.path=="/home"){
        this.$refs.but1.style.background= '#67C23A';
        this.$refs.but2.style.background= 'rgba(255, 105, 180, 0.671)';
        this.$refs.but3.style.background= 'rgba(255, 105, 180, 0.671)';
      }
      if(to.path=="/content"){
        this.$refs.but1.style.background= 'rgba(255, 105, 180, 0.671)';
        this.$refs.but2.style.background= 'rgba(255, 105, 180, 0.671)';
        this.$refs.but3.style.background= '#67C23A';
      }
      if(to.path=="/introduce"){
        this.$refs.but1.style.background= 'rgba(255, 105, 180, 0.671)';
        this.$refs.but2.style.background= '#67C23A';
        this.$refs.but3.style.background= 'rgba(255, 105, 180, 0.671)';
      }
    }
  },
  mounted(){
    if(this.$route.name=="home"){
      this.$refs.but1.style.background= '#67C23A';
    }
    if(this.$route.name=="introduce"){
      this.$refs.but2.style.background= '#67C23A';
    }
    if(this.$route.name=="content"){
      this.$refs.but3.style.background= '#67C23A';
    }
    this.$store.dispatch('activitiesHistoryChange');
    if(this.$route.name!=null){
      this.loadingF=false;
    }
  }
}
</script>

<style>
*{margin: 0px; padding: 0px;box-sizing: border-box;}
#app {
  /* http://127.0.0.1:3000/img?name=Bow.png */
  /* -webkit-font-smoothing: antialiased; */
  /* display: flex; */
  position: absolute;
  overflow: hidden;
  background: url('http://img.saohimeyue.space/img?name=homez.jpg') no-repeat;
  background-size: cover;
  background-position:0px -500px;
  height: 100%;
  width: 100%;
  min-width: 990px;
  min-height: 695px;
}

.operate{
  position: absolute;
  z-index: 30;
  bottom: 20px;
  left: 13vw;
  border-radius:1vw;
  width: 74%;
  /* min-width: 1300px; */
  height: 28px;
  border-right: 5px solid rgba(255, 105, 180, 0.4);
  border-left: 5px solid rgba(255, 105, 180, 0.4);
  /* background:linear-gradient(to left,rgba(255, 105, 180, 0.2),rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.2),rgba(255, 105, 180, 0.2)); */
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.operate .butz{
  width: 46px;
  height: 46px;
  border-radius:23px;
  background-color: rgba(255, 105, 180, 0.7);
  border: 3px solid rgb(255, 255, 255);
  backdrop-filter: blur(10px);

}

.operate button{
  background: transparent;
  border: 0px ;
  margin: 0px;
}
.operate button:focus{
  background: transparent;
  border: 0px ;
}
.operate button:hover{
  background: rgba(0, 0, 0, 0.05);
  border: 0px ;
}
.operate .butz:hover{
  background: rgba(0, 0, 0, 0.05);
  border: 3px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 480px){
  #app{
    background-position:0px 0px;
    min-width: 200px;
    min-height: 200px;
  }
  .operate{
    min-width: 100px;
    width: 90%;
    left: 5%;
  }

}

.loading{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content:center;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius:30px;
}
.loading img{
  display: none;
}

.loading span{
  font-size: 1.5rem;
  z-index: 5000;
  position: absolute;
  top: 55%;
  color: #67C23A;
}

#app .el-loading-mask{
  background-color: transparent;
}



</style>




